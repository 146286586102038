import React, {useEffect} from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

//import illustration from '../../images/homepage/equipe/illustration-equipe.png';
import {gsap} from "gsap";
import {useTranslation} from "react-i18next";

const TeamLvmh = () => {

    const { t } = useTranslation();
    const mm = gsap.matchMedia();

    useEffect(() => {

        mm.add("(min-width: 992px)", () => {
            gsap.to('.teammate-container', {
                autoAlpha: 1,
                duration: 1,
                stagger: 0.5,
                scrollTrigger: {
                    trigger: '#our-team',
                    start: 'top +70%',
                    markers: false,
                }
            });
        });

    }, [mm]);

    return (

        <Row className="section-team justify-content-center">
            <Col xs="12" lg>
                <div className="teammate-container">
                    <div className="teammate-name">{t('team.teamate1.name')}</div>
                    <div className="teammate-position">{t('team.teamate1.position')}</div>
                </div>


                <div className="teammate-container">
                    <div className="teammate-name">{t('team.teamate2.name')}</div>
                    <div className="teammate-position">{t('team.teamate2.position')}</div>
                </div>
            </Col>
            <Col data-speed="0.95" xs="12" lg="auto">
                {/*<div className="teammate-image">
                    <img src={illustration} className="img-fluid" aria-hidden="true" alt=""/>
                </div>*/}
            </Col>
            <Col xs="12" lg>
                <div className="teammate-container">
                    <div className="teammate-name">{t('team.teamate3.name')}</div>
                    <div className="teammate-position">{t('team.teamate3.position')}</div>
                </div>

                <div className="teammate-container">
                    <div className="teammate-name">{t('team.teamate4.name')}</div>
                    <div className="teammate-position">{t('team.teamate4.position')}</div>
                </div>
            </Col>
        </Row>

    )


}

export default TeamLvmh;