import React, {useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger,ScrollSmoother,ScrollToPlugin);

const Animations = () => {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();
    const { i18n, t } = useTranslation();
    let sMoother = useRef(null);
    let sTrigger = useRef(null);

    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {

        /*let fullPath = window.location.pathname.split('/');
        if(fullPath.includes('404') || fullPath.includes('disclaimer') || fullPath.includes('informations')){
            let body = document.body;
            body.classList.add('remove-header-footer');
        }else{
            let body = document.body;
            body.classList.remove('remove-header-footer');
        }*/

        /**
         * CALL ANCHOR ONLOAD
         * @type {string}
         */
        /*let urlHash = window.location.href.split("#")[1];
        let scrollElem = document.querySelector("#" + urlHash);

        if (urlHash && scrollElem) {
            /!*gsap.to(sMoother, {
                scrollTop: sMoother.offset(scrollElem, "top 100"),
                duration: 1,
                delay: 0.5
            });*!/
            sMoother.scrollTo(scrollElem,true,"top 100px");
        }*/


        /**
         * MANAGE CLASSES ON HP
         * @type {Element}
         */
        /*const header  = document.querySelector(".header");
        if(fullPath.length === 2){
            //console.log('Home page detected');
            header.classList.add('animated-intro');
        }else{
            //console.log('Other page than HP detected');
            header.classList.remove('animated-intro');
        }*/

    }, [pathname]);


    return (
        <a href="#" className="progress-wrap" title="">
            <span className="visually-hidden sr-only">{t('app.backToTopLabel')}</span>
            <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
            </svg>
        </a>

    )
}

export default Animations