import React from "react";

import {Link, NavLink} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useTranslation} from "react-i18next";

import {socialNetworks} from "../../constants/index";

const Footer = () =>{

    const { i18n,t } = useTranslation();
    const currentLanguage = i18n.language;

    return (

        <footer role="contentinfo">
            <Container fluid className="footer section-related-to-nav" id="contact-us">
                <Row className="inner-footer">
                    <Col>
                        <Container>
                            <Row className="footer-content">
                                <Col xs="8" lg="6">
                                    <div className="footer-title text-start">
                                        <a href={`mailTo:` + t('footer.contactEmail')}>{t('footer.title')}</a>
                                    </div>
                                    <div className="footer-text text-start">
                                        <a href={t('footer.lvmhUrl')}>{t('footer.lvmhText')}</a>
                                    </div>
                                </Col>

                                <Col xs="4" lg="6" className="footer-contact text-end">
                                    <div className="footer-contact-link">
                                        &nbsp;
                                    </div>
                                    <div className="footer-social">
                                        <ul>
                                            {socialNetworks.map((social,index) => (
                                                <li key={index}>
                                                    <a href={social.url} target="_blank" rel="noreferrer" title={social.title} dangerouslySetInnerHTML={{__html:social.svg}}></a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                </Col>

                            </Row>

                        </Container>
                    </Col>
                </Row>
                <Row className="post-footer">
                    <Col>
                        <Container>
                            <Row className="justify-content-center justify-content-lg-between">
                                <Col className="order-1 order-lg-0 text-center text-lg-start" xs="12" lg>
                                    <div className="footer-copyright">
                                        {t('footer.copyright')}
                                    </div>
                                </Col>
                                <Col className="order-0 order-lg-1 text-center text-lg-end" xs="12" lg="auto">
                                    <div className="footer-menu">
                                        <ul className="nav-footer">
                                            <li>
                                                <Link to="/accessibility">
                                                    {t('footer.menuAccess')}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/mentions">
                                                    {t('footer.menu')}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="justify-content-center footer-mention">
                                <Col className="text-center" xs="12" lg>
                                    <p className="footer-mentions">
                                        {t('footer.mentions')}
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

            </Container>

        </footer>
    )


}

export default Footer;