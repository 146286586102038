import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        backend: {
            // for all available options read the backend's repository readme file
            loadPath: './locales/{{lng}}/{{ns}}.json'
        },
        debug: false,
        whitelist: ['fr', 'en'],
        fallbackLng: "en",
        load: 'languageOnly',
        detection: {
            order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
            lookupFromPathIndex: 0,
            checkWhitelist: true,
            lookupQuerystring: 'lng',
            lookupCookie: 'i18n',
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'],
            cookieMinutes: 10,
            htmlTag: document.documentElement
        },
        interpolation: {
            escapeValue: false,
        },
        react: {
            wait: true
        },
    }).then(r => '');

export default i18n;