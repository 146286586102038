import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import imageSectionEntiteOne from "../images/homepage/entite-1.jpg";
import imageSectionEntiteTwo from "../images/homepage/entite-2.jpg";

import {Trans, useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import {gsap} from "gsap";

const Mentions = () => {

    const { t } = useTranslation(["access"]);
    const mm = gsap.matchMedia();


    useEffect(() => {

        //window.scrollTo(0, 0);
        document.body.classList.remove('page-homepage');
        document.body.classList.add('page-content');

        mm.add("(min-width: 992px)", () => {
            gsap.fromTo(".section-image-double .image-container.effect",
                {
                    top: 250
                },
                {
                    top: -15,
                    scrollTrigger: {
                        trigger: '.section-image-double ',
                        start: 'top +70%',
                        markers: false,
                        scrub: 1.5
                    }
                },
            );
        });

    },[]);

    return (

        <>
            <Helmet>
                <title>{t('seo.name')} | {t('seo.title_mentions')}</title>
                <meta name="description" content={t('seo.description_mentions')}/>
            </Helmet>

            <main role="main" className="accessibility">
                <Container fluid className="main-content">
                    <Row className="section">
                        <Col>
                            <Container >

                                <Row>
                                    <Col>
                                        <Row className="section-title justify-content-center">
                                            <Col xs="auto">
                                                <div className="text-title text-center">
                                                    <h1>{t('page.title')}</h1>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>


                                <Row className="section-title-text left-title">
                                    <Col xs="12" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_intro_text"> </Trans>
                                                <Trans t={t}>
                                                    <a href='https://vinsdexception.lvmh.com/' target="_self" rel="noreferrer">https://vinsdexception.lvmh.com/</a>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text small-marge">
                                    <Col xs="12" className="text-container">
                                        <div className="text-container-line">
                                            <h2 className="text-container-title h2 with-separator">
                                                <span>{t('page.section_1')}</span>
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">&nbsp;</Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_1_text"> </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text small-marge">
                                    <Col xs="12" className="text-container">
                                        <div className="text-container-line">
                                            <h2 className="text-container-title h2 with-separator">
                                                <span>{t('page.section_2')}</span>
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">&nbsp;</Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_2_text"> </Trans>
                                                <Trans t={t} i18nKey="section_2_list_1">
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                                <Trans t={t} i18nKey="section_2_text_2"> </Trans>
                                                <Trans t={t} i18nKey="section_2_list_2">
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text small-marge">
                                    <Col xs="12" className="text-container">
                                        <div className="text-container-line">
                                            <h2 className="text-container-title h2 with-separator">
                                                <span>{t('page.section_3')}</span>
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">&nbsp;</Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_3_text"> </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">I.</span>{t('section_3_1_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_3_1_text"> </Trans>
                                                <Trans t={t} i18nKey="section_3_1_list">
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">II.</span>{t('section_3_2_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_3_2_list">
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text small-marge">
                                    <Col xs="12" className="text-container">
                                        <div className="text-container-line">
                                            <h2 className="text-container-title h2 with-separator">
                                                <span>{t('page.section_4')}</span>
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">&nbsp;</Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_4_text"> </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">I.</span>{t('section_4_1_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_4_1_list" >
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">II.</span>{t('section_4_2_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_4_2_text" > </Trans>
                                                <Trans t={t} i18nKey="section_4_2_list" >
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">III.</span>{t('section_4_3_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_4_3_list" >
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">IV.</span>{t('section_4_4_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_4_4_list" >
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text small-marge">
                                    <Col xs="12" className="text-container">
                                        <div className="text-container-line">
                                            <h2 className="text-container-title h2 with-separator">
                                                <span>{t('page.section_5')}</span>
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">&nbsp;</Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_5_text" > </Trans>
                                                <Trans t={t} i18nKey="section_5_list" >
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text small-marge">
                                    <Col xs="12" className="text-container">
                                        <div className="text-container-line">
                                            <h2 className="text-container-title h2 with-separator">
                                                <span>{t('page.section_6')}</span>
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">&nbsp;</Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_6_text" > </Trans>
                                                <Trans t={t} i18nKey="section_6_list" >
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Container>
                        </Col>
                    </Row>
                </Container>

            </main>

        </>
    );
}

export default Mentions;