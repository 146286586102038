import React, {useEffect} from "react";

import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import Image404 from "../../images/errors/404-texte.jpg";


export default function ErrorPage() {
    const { i18n,t } = useTranslation();

    useEffect(() => {
        let body = document.body;
        body.classList.add('remove-header-footer');
    });

    return (
        <>
            <Helmet>
                <title>{t('website.name')} | {t('seo.title_404')}</title>
                <meta name="description" content={t('seo.description_404')}/>
            </Helmet>

            <div className="error-page">
                <Container fluid className="main-content h-100">
                    <Row className="h-100">
                        <Col className="align-self-center">
                            <Container className="error-page-container">
                                <Row>
                                    <Col className="error-page-content">
                                        <div className="text-title">
                                            <h1>{t('404.title')}</h1>
                                        </div>

                                        <div className="image-error">
                                            <img src={Image404} alt="" className="img-fluid"/>
                                        </div>

                                        <div className="text-content">
                                            <p>{t('404.description')}</p>
                                            <Link to="/">{t('404.cta')}</Link>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}