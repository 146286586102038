import React, {useEffect, useRef, useState} from "react";
import {NavLink} from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from "../../constants/index";

import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";



import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Logo from '../../images/identity/logo.svg';
import Animations from "../Animations/Animations";



gsap.registerPlugin(ScrollTrigger,ScrollSmoother,ScrollToPlugin);
const Header = (props) => {

    let sMoother = useRef(null);
    let sTrigger = useRef(null);
    const { i18n, t } = useTranslation();
    const currentLanguage = i18n.language;

    // Define lang attribute on html tag
    document.documentElement.lang = currentLanguage;

    const menuActive = useRef(false);
    const manageNavigation = () =>{

        menuActive.current = !menuActive.current;
        if(menuActive.current){
            document.body.classList.add('toggle-menu-layout');
            document.querySelector(".hamburger").classList.add('is-active');
            document.querySelector(".header-mobile").classList.add('is-active');
        }else{
            document.body.classList.remove('toggle-menu-layout');
            document.querySelector(".hamburger").classList.remove('is-active');
            document.querySelector(".header-mobile").classList.remove('is-active');
        }
    };

    const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const code = e.target.value
        const previousLanguage = i18n.language;
        i18n.changeLanguage(code);

        // Update lang attribute on html tag
        document.documentElement.lang = i18n.language;
    }

    const mm = gsap.matchMedia();

    //const { pathname } = useLocation();


    useEffect(() => {

        document.querySelector(".progress-wrap").addEventListener("click", e => {
            window.scrollTo(0, 0);
        });

        mm.add("(min-width: 992px)", () => {

            ScrollTrigger.refresh();


            /**
             * SCROLL TO TOP
             * ScrollToTopEffect path length on scroll
             * @param scrollY
             */
            const progressPathCircle= (scrollY)=> {
                const progressPath = document.querySelector('.progress-circle path');
                const pathLength = progressPath.getTotalLength();

                progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
                progressPath.style.strokeDashoffset = pathLength;
                progressPath.getBoundingClientRect();
                progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';

                var height = window.document.body.offsetHeight - window.innerHeight;
                var progress = pathLength - (scrollY * pathLength / height);
                progressPath.style.strokeDashoffset = progress;
            }


            /**
             * SCROLL TO TOP - EVENT
             **/

            document.querySelector(".progress-wrap").addEventListener("click", e => {
                window.scrollTo(0, 0);
            });

            sMoother = ScrollSmoother.create({
                content : "#smooth-content",
                smooth: 1.5,
                effects: true,
                markers : false,
                onUpdate: (self) => progressPathCircle( self.scrollTop()),
            });

            /**
             * SCROLL TO TOP -  BUTTON DISPLAYED
             */
            sTrigger = ScrollTrigger.create({
                start: 'top -75vh',
                end: 99999999999999999,
                toggleClass: {className: 'is-displayed', targets: '.progress-wrap'},
                scrub: false,
                markers : false
            });


            /**
             * ANIMATE Section
             */
            sMoother.effects(".smooth-09", {speed: 0.9});
            sMoother.effects(".smooth-10", {speed: 1.25});
            sMoother.effects(".smooth-11", {speed: 1.1});
            sMoother.effects(".smooth-115", {speed: 1.15});
            sMoother.effects(".smooth-illustration-intro", {speed: 1.35});

        });

        /**
         * NAVIGATION
         */
        gsap.utils.toArray(".nav a").forEach(function (button, i) {
            button.addEventListener("click", (e) => {

/*
                var url = e.target.getAttribute("href");
                var urlHashPos = url.indexOf('#');
                var id = url.substring(urlHashPos,urlHashPos.length);
                //sMoother.scrollTo(id, true, "top 100px");

                let scrollElem = document.querySelector(id);

                window.scrollTo(0, (scrollElem.offsetTop-100));*/

                setTimeout(() => {
                    menuActive.current = !menuActive.current;
                    document.body.classList.remove('toggle-menu-layout');
                    document.querySelector(".hamburger").classList.remove('is-active');
                    document.querySelector(".header-mobile").classList.remove('is-active');
                },150);

                //e.preventDefault();
            });
        });


    }, [mm]);

    /**
     * MODAL
     */
    const [showModal, setShow] = useState( true);
    const handleClose = (evt) => {
        setShow(false);
        //setModalAnswer(evt.target.id);
        setBodyStatic(false);
    }
    const handleShow = () => {
        setShow(true);
        setBodyStatic(true);
    }
    const setBodyStatic = (isStatic) => {
        if(isStatic){
            document.documentElement.classList.add('no-scroll');
        }else{
            document.documentElement.classList.remove('no-scroll');
        }
    }

    /**
     * END MODAL
     */

    return (
        <header role="banner" >
            <Animations />
            <Modal
                show={showModal}
                backdrop="static"
                keyboard={false}
                onHide = { function(){
                    handleClose();
                }}
                onShow={ function(){
                    handleShow();
                }}
                size="md"
                aria-labelledby="modal-title"
                aria-describedby="modal-caption"
                centered
                className="agegate-modal"
                role="alertdialog"
            >
                <Modal.Header>
                    <div className="modal-logo">
                        <img src={Logo} alt={t('website.name')} lang="fr"/>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <h2 id="modal-title">{t('modal_agegate.title')}</h2>
                    <p id="modal-caption">
                        {t('modal_agegate.description')}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="button-container">
                        <Button className="btn-primary" id="yes" onClick={(e) => handleClose(e)}>{t('modal_agegate.cta_yes')}</Button>
                        <Link className="btn-primary" id="no" onClick={(e) => handleClose(e)} to="/disclaimer">{t('modal_agegate.cta_no')}</Link>
                    </div>

                    <div className="mentions-container">
                        {t('footer.mentions')}
                    </div>
                </Modal.Footer>
            </Modal>
            <Container fluid className="header">

                <Row className="header-mobile align-content-center d-lg-none">
                    <Col>
                        <nav role="navigation" className="navigation">
                            <ul className="nav">
                                <li className="navigation-top-item" id="nav-our-estates-mobile">
                                    <Link to="/#our-estates">
                                        {t('navigation.properties')}
                                    </Link>
                                </li>
                                <li className="navigation-top-item" id="nav-about-us-mobile">
                                    <Link to="/#about-us">
                                        {t('navigation.entity')}
                                    </Link>
                                </li>
                                <li className="navigation-top-item" id="nav-our-team-mobile">
                                    <Link to="/#our-team">
                                        {t('navigation.team')}
                                    </Link>
                                </li>
                                <li className="navigation-top-item" id="nav-contact-us-mobile">
                                    <Link to="/#contact-us">
                                        {t('navigation.contact')}
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </Col>
                </Row>

                <Row className="header-common ">
                    <Col>
                        <nav className="row justify-content-center align-items-start navigation">

                                <Col xs="auto" className="header-burger-menu d-lg-none">
                                    <button type="button" className="hamburger" onClick={manageNavigation}>
                                        <span className="visually-hidden">Menu mobile</span>
                                        <span className="line"></span>
                                        <span className="line"></span>
                                        <span className="line"></span>
                                    </button>
                                </Col>

                                <Col xs="12" lg="auto" className="d-none d-lg-block">
                                    <div role="navigation" className="navigation navigation-left">
                                        <ul className="nav">
                                            <li className="navigation-top-item" id="nav-our-estates">
                                                <Link to="/#our-estates">
                                                    {t('navigation.properties')}
                                                </Link>
                                            </li>
                                            <li className="navigation-top-item" id="nav-about-us">
                                                <Link to="/#about-us">
                                                    {t('navigation.entity')}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>

                                <Col xs lg="auto">
                                    <div className="navigation navigation-center">
                                        <div className="logo h1">
                                            <a href="/">
                                                <img src={Logo} alt={t('website.nameLink')}/>
                                            </a>
                                        </div>
                                    </div>
                                </Col>

                                <Col xs="12" lg="auto" className="d-none d-lg-block">
                                    <div role="navigation" className="navigation navigation-right">
                                        <ul className="nav">
                                            <li className="navigation-top-item" id="nav-our-team">
                                                <Link to="/#our-team">
                                                    {t('navigation.team')}
                                                </Link>
                                            </li>
                                            <li className="navigation-top-item" id="nav-contact-us">
                                                <Link to="/#contact-us">
                                                    {t('navigation.contact')}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </Col>

                                <div className="navigation navigation-lang">
                                    <label htmlFor="language-selector" className="visually-hidden">{t('navigation.langLabel')}</label>
                                    <select defaultValue={i18n.language} onChange={onChangeLang} id="language-selector" name={t('navigation.langLabel')} >
                                        {
                                            LANGUAGES.map(({ code, label }) => (
                                                <option
                                                    key={code}
                                                    value={code}
                                                    lang={code}
                                                    name={label}
                                                    id={code}
                                                    sr-only={label}
                                                >{code}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                            </nav>
                    </Col>
                </Row>
            </Container>
        </header>
    )



}

export default Header;