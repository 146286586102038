import React, {useEffect} from "react";

import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

import Logo from "../../images/identity/logo.svg";


export default function AgeGateError() {

    const { i18n,t } = useTranslation(["disclaimer"]);

    useEffect(() => {
        let body = document.body;
        body.classList.add('remove-header-footer');
    });

    return (
        <>
            <Helmet>
                <title>{t('website.name')} | {t('seo.title_page')}</title>
                <meta name="description" content={t('seo.description_page')}/>
            </Helmet>

            <div className="age-gate-error-page">
                <Container fluid className="main-content h-100">
                    <Row className="h-100">
                        <Col className="align-self-center">
                            <Container className="error-page-container">
                                <Row>
                                    <Col className="error-page-content">

                                        <div className="image-error">
                                            <img src={Logo} alt="" className="img-fluid"/>
                                        </div>

                                        <div className="text-title">
                                            <h1>{t('page.title')}</h1>
                                        </div>

                                        <div className="text-content">
                                            <p>{t('page.description')}</p>
                                            <a href="/">{t('cta.home_text')}</a>
                                        </div>

                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}