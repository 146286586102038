import React, {useEffect, useRef} from "react";

import { ScrollTrigger } from "gsap/ScrollTrigger";

import SliderLvmh from "./SliderLvmh/SliderLvmh";
import TeamLvmh from "./TeamLvmh/TeamLvmh";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Logo from '../images/identity/logo.svg';

import imageSectionOne from '../images/homepage/section-1.jpg';

import imageSectionTwo from '../images/homepage/section-2_1.jpg';
import imageSectionTwoTwo from '../images/homepage/section-2_2.jpg';

import imageSectionPropriete from '../images/homepage/proprietes/estate-bg.jpg';
import imageSectionPreFooter from '../images/homepage/pre-footer-merged.jpg';

import imageSectionEntiteOne from '../images/homepage/entite-1.jpg';
import imageSectionEntiteTwo from '../images/homepage/entite-2.jpg';

import {Helmet} from "react-helmet";
import {gsap} from "gsap";

import { useTranslation } from 'react-i18next';
import {useLocation} from "react-router-dom";

import { propertiesName } from "../constants/index";

gsap.registerPlugin(ScrollTrigger);

const Home = () => {

    const sTrigger = useRef(null);

    const { t } = useTranslation();
    const mm = gsap.matchMedia();

    const { pathname } = useLocation();
    useEffect(() => {
        document.body.classList.remove('page-content');
        document.body.classList.add('page-homepage');

        mm.add("(min-width: 992px)", () => {

            /**
             * ANIMATE INTRO SECTION
             */
            ScrollTrigger.refresh();

            sTrigger.current = ScrollTrigger.create({
                start: 'top -30%',
                end: 99999999999999999,
                toggleClass: {className: 'header-condensed', targets: '.header'},
                markers: false,
                //onUpdate: (self) => console.log("progress", self),
            });

            gsap.to('#logo', {
                scale: 0.3,
                ease: 'easeInOut',
                scrollTrigger: {
                    start: 'top',
                    end: "+=30%",
                    markers: false,
                    scrub: 0.3
                }
            });


            /**
             * ANIMATE DOUBLE IMAGE
             */
            gsap.fromTo(".section-image-double .image-container.effect",
                {
                    top: 200
                },
                {
                    top: -15,
                    scrollTrigger: {
                        trigger: '.section-image-double ',
                        start: 'top +50%',
                        markers: false,
                        scrub: 1.5
                    }
                },
            );

        });
        mm.add("(max-width: 991px)", () => {
            window.scrollTo(0, 0);
        });
        /**
         * Add trigger on section to detect witch one is active in the viewport
         * @type {unknown[]}
         */

        const sections = gsap.utils.toArray(".section-related-to-nav");
        sections.forEach((section) => {
            gsap.to(section, {
                scrollTrigger: {
                    trigger: section,
                    start: "top +80%",
                    scrub: true,
                    markers: false,
                    onEnter: (self) => navSectionActive(self.trigger.id),
                    onEnterBack: (self) => navSectionActive(self.trigger.id),
                    onLeaveBack: (self) => navSectionLeaveBackward(self.trigger.id),
                }
            })
        });

        /**
         * Section leaving backward / Remove active links
         * @param id
         */
        const navSectionLeaveBackward = (id) => {
            let elem = document.getElementById('nav-' + id);
            elem.classList.remove('is-active');
        }

        /**
         * Section enter(back) / Enable active links
         * @param id
         */
        const navSectionActive = (id) => {

            let elem = document.getElementById('nav-' + id);
            let elemsNav = document.getElementsByClassName('navigation-top-item is-active');

            if (elemsNav) {
                while (elemsNav[0]) {
                    elemsNav[0].classList.remove('is-active')
                }
            }

            elem.classList.add('is-active');
        }

    }, [mm,pathname]);

   /* useEffect(() => {
        let urlHash = window.location.href.split("#")[1];
        let scrollElem = document.querySelector("#" + urlHash);

        if (urlHash && scrollElem) {
            window.scrollTo(0, (scrollElem.offsetTop-100));

            setTimeout(() => {
                document.body.classList.remove('toggle-menu-layout');
                document.querySelector(".hamburger").classList.remove('is-active');
                document.querySelector(".header-mobile").classList.remove('is-active');
            },150);

        }
    },[])*/

    return (
        <>
            <Helmet>
                <title>{t('website.name')} | {t('seo.title_hp')}</title>
                <meta name="description" content={t('seo.description_hp')}/>
            </Helmet>

            <main role="main" className="home">
                <Container fluid className="main-content">

                    <Row className="section-logo-hp">
                        <Col>
                            <Container className="h-100 position-relative">
                                <Row className="justify-content-center align-items-center h-100">
                                    <Col xs="auto">
                                        <h1 className="m-0">
                                        <img src={Logo} id="logo" alt={t('website.name')}/>
                                        </h1>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>

                    </Row>

                    <Row>
                        <Col>
                            <Container>
                                <Row className="section">

                                    <Col>

                                        <Row className="section-image-text left-image align-items-start">
                                            <Col xs="12" lg="5" className="image-container smooth-11">
                                                <img src={imageSectionOne} className="img-fluid" aria-hidden="true" alt=""/>
                                            </Col>

                                            <Col xs="12" lg="6" className="text-container offset-lg-1 smooth-115">
                                                <div className="text-container-line">
                                                    <h3 className="text-container-title">
                                                        {t('home.section1Title1')}
                                                    </h3>
                                                    <div className="text-container-content">
                                                        {t('home.section1Text1')}
                                                    </div>
                                                </div>

                                                <div className="text-container-line">
                                                    <h3 className="text-container-title">
                                                        {t('home.section1Title2')}
                                                    </h3>
                                                    <div className="text-container-content">
                                                        {t('home.section1Text2')}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="section">

                                    <Col>
                                        <Row className="section-image-text right-image align-items-start">

                                            <Col xs="12" lg="6" className="text-container order-1 order-lg-0">
                                                <div className="text-container-line smooth-11">
                                                    <h3 className="text-container-title">
                                                        {t('home.section2Title1')}
                                                    </h3>
                                                    <div className="text-container-content">
                                                        {t('home.section2Text1')}
                                                    </div>
                                                </div>

                                                <div className="text-container-line order-0 order-lg-1 smooth-11">
                                                    <h3 className="text-container-title">
                                                        {t('home.section2Title2')}
                                                    </h3>
                                                </div>
                                            </Col>

                                            <Col xs="12" lg="5" className="image-container overlapping-images offset-lg-1">
                                                <img src={imageSectionTwo} className="big-image img-fluid smooth-115" aria-hidden="true"  alt=""/>
                                                <img src={imageSectionTwoTwo} className="small-image img-fluid smooth-11" aria-hidden="true"  alt=""/>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                    {/*PROPRIÉTÉS*/}
                    <Row className="section section-related-to-nav" id="our-estates">
                        <Col>
                            <Row>
                                <Col className="section-image-fullwidth with-title title-bottom fixed-background">
                                    <img src={imageSectionPropriete} aria-hidden="true" alt=""/>
                                    <h2 className="title-container">{t('properties.title')}</h2>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Container>
                                        <Row className="section">
                                            <Col>
                                                <SliderLvmh dataProperties={propertiesName} />
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                    {/*END PROPRIÉTÉS*/}

                    <Row>
                        <Col>
                            <Container>
                                <Row className="section">

                                    <Col>
                                        <div className="section-hr"></div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                    {/*ENTITÉ*/}
                    <Row className="section section-related-to-nav" id="about-us">
                        <Col>
                            <Container className="">
                                <Row>
                                    <Col>
                                        <Row className="section-title justify-content-center">
                                            <Col xs="auto">
                                                <h2 className="text-title">
                                                    {t('entity.title')}
                                                </h2>
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col>

                                                <Row className="section-title-text left-title">
                                                    <Col xs="12" lg="5" className="text-container smooth-09">
                                                        <div className="text-container-line">
                                                            <h3 className="text-container-title">
                                                                <span className="text-container-title-number">I.</span>{t('entity.section1Title')}
                                                            </h3>
                                                        </div>
                                                    </Col>

                                                    <Col xs="12" lg="6" className="text-container offset-lg-1">
                                                        <div className="text-container-line">
                                                            <div className="text-container-content">
                                                                {t('entity.section1Text')}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Row className="section-image-double">
                                                    <Col xs="12" lg="6" className="image-container effect">
                                                        <img src={imageSectionEntiteOne} className="img-fluid" aria-hidden="true" alt=""/>
                                                    </Col>

                                                    <Col  xs="12" lg="6" className="image-container">
                                                        <img src={imageSectionEntiteTwo} className="img-fluid" aria-hidden="true" alt=""/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Container>

                                            <Row className="section">

                                                <Col>

                                                    <Row className="section-title-text left-title">
                                                        <Col xs="12" lg="5" className="text-container">
                                                            <div className="text-container-line smooth-09">
                                                                <h3 className="text-container-title">
                                                                    <span className="text-container-title-number">II.</span>{t('entity.section2Title')}
                                                                </h3>
                                                            </div>
                                                        </Col>

                                                        <Col xs="12" lg="6" className="text-container offset-lg-1">
                                                            <div className="text-container-line">
                                                                <div className="text-container-content">
                                                                    {t('entity.section2Text')}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>


                            </Container>
                        </Col>
                    </Row>
                    {/*END - ENTITÉ*/}

                    {/*ÉQUIPE*/}
                    <Row className="section section-related-to-nav" id="our-team">
                        <Col>
                            <Container>
                                <Row className="justify-content-center">
                                    <Col xs="12" lg="8">
                                        <Row className="section-title justify-content-center">
                                            <Col xs="auto">
                                                <h2 className="text-title">{t('team.title')}</h2>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <TeamLvmh />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    {/*ÉQUIPE*/}

                    <Row className="section">
                        <Col className="section-image-fullwidth with-title title-bottom" style={{backgroundImage:`url(${imageSectionPreFooter})`}}></Col>
                    </Row>

                </Container>
            </main>
        </>
    );
}

export default Home;