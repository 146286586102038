import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import React, {Suspense, useEffect} from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollSmoother } from "gsap/ScrollSmoother";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate, HashRouter,
} from "react-router-dom";

import "./constants/i18n";
import {useTranslation} from "react-i18next";
import { I18nContext } from './constants/i18nContext';

//import AppLoading from "./Components/AppLoading/AppLoading";

import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";

import Home from "./Components/Home";
import Mentions from "./Components/Mentions";
import ErrorPage from "./Components/ErrorPage/error-page";
import AgeGateError from "./Components/AgeGateError/age-gate-error";
import Accessibility from "./Components/Accessibility";

// Enregistrement des plugins GSAP
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, ScrollSmoother);

function App() {

    const welcomeMsg = () => {
        let msg =
            "%c 💚 Welcome on this website.You don't have to be here, do you ? 💚";
        let styles = [
            "font-size: 12px",
            "color: #ffffff",
            "font-family: monospace",
            "background: #0e100f",
            "display: inline-block",
            "padding: 1rem 3rem",
            "border: 1px solid #fffce1",
            "border-radius: 4px;",
        ].join(";");
        console.log(msg, styles);
    };
    welcomeMsg();

    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    useEffect(() => {
        const handleLanguageChange = (code) => {
            i18n.changeLanguage(code);
        };

        const lang = window.location.pathname.split('/')[1];
        if (['en', 'fr'].includes(lang)) {
            handleLanguageChange(lang);
            /**
             * Update SELECT Value when lang change in URL
             * @type {NodeListOf<HTMLElement>}
             */
            let lngSelect = document.getElementById('language-selector')
            if(lngSelect) {
                lngSelect.value = lang;
                lngSelect.dispatchEvent(new Event('change'));
            }
        }

    }, [i18n]);

    //https://stackoverflow.com/a/71842594

    return (
        <Suspense fallback="...is loading">
        <I18nContext.Provider value={i18n}>
            <HashRouter >
                <Header />
                <div id="smooth-wrapper">
                    <div id="smooth-content">
                        <div id="top"></div>
                        <Routes>
                            <Route path="/" exact element={<Home />} />
                            <Route path="/mentions" exact element={<Mentions />} />
                            <Route path="/accessibility" element={<Accessibility />} />
                            <Route path="/disclaimer" element={ <AgeGateError /> } />
                            <Route path="*" element={ <ErrorPage /> } />
                        </Routes>
                        <Footer />
                    </div>
                </div>
            </HashRouter >
        </I18nContext.Provider>
        </Suspense>
    );
}

export default App;
