import 'slick-carousel/slick/slick.css';

import React, {Component} from "react";
import { withTranslation  } from 'react-i18next';
import Slider from "react-slick";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {gsap} from "gsap";


class SliderLvmh extends Component {
    constructor(props) {
        super(props);
        this.sliderRef = React.createRef();
        this.state = {
            counter: 0,
            totalSlide: 0,
            link : null,
            linkEstateName : null,
            active: false,
        };
    }

    render() {

        const propertiesDatasSlider = this.props.dataProperties;
        const { counter } = this.state;
        const { totalSlide } = this.state;
        const { link } = this.state;
        const { linkEstateName } = this.state;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows : false,
            fade : true,
            autoplay : false,
            swipe : false,
            afterChange: (currentSlide : number) => handleAfterChangeSlide(currentSlide),
            beforeChange: (currentSlide : number) => handleBeforeChangeSlide(currentSlide),
            //onInit : () => handleAfterChangeSlide(counter)
            onInit : () => handleInitCall(propertiesDatasSlider.length,counter)
        };

        const handleInitCall = (sliderLength,counter) => {
            //console.log(sliderLength,counter);

            //let currentSliderSteps = document.querySelector(".slider-container .slider-steps .current-step");
            //let totalSliderSteps = document.querySelector(".slider-container .slider-steps .total-steps");

            this.setState((prevState) => {
                return {
                    totalSlide: sliderLength
                };
            });

            /**
             * Call initial change slide method
             */
            handleAfterChangeSlide(counter);
        }

        const handleAfterChangeSlide = (currentSlide: number) => {
            this.setState((prevState) => {
                return {
                    counter: currentSlide + 1,
                    link : propertiesDatasSlider[currentSlide].url,
                    linkEstateName : propertiesDatasSlider[currentSlide].name
                };
            });

            let currentSlideActiveTitle = document.querySelector("[data-index='"+currentSlide+"'] .slide-title .title");
            let currentSlideActiveDate = document.querySelector("[data-index='"+currentSlide+"'] .slide-title .date");
            //let currentSlideActiveImage = document.querySelector("[data-index='"+currentSlide+"'] .slide-title .slide-image");
            let currentSliderSteps = document.querySelector(".slider-container .slider-steps .current-step");
            let currentNavSteps = document.querySelector("[data-slide='"+currentSlide+"'].navigation-item");
            let sliderLink = document.querySelector(".slider-container .slide-link");

            currentNavSteps.classList.add('active');

            /**
             * Animate Steps - Slide In
             */
            let supposedStepHeight = 30;
            gsap.to(currentSliderSteps,
                {
                    top : -1*(currentSlide * supposedStepHeight),
                    duration : 1.1,
                    ease: "elastic.out"
                },
            );

            /**
             * Animate Title - Appear
             */
            gsap.fromTo(currentSlideActiveTitle,
                {
                    y : 100,
                    opacity : 0
                },
                {
                    y : 0,
                    opacity : 1,
                    duration : 0.35,
                    ease: "power2.Out"
                },
            );

            /**
             * Animate Date - Appear
             */
            gsap.fromTo(currentSlideActiveDate,
                {
                    x : 15,
                    opacity : 0
                },
                {
                    x : 0,
                    opacity : 1,
                    duration : 0.75,
                    ease: "power2.Out"
                },
            );

            /**
             * Animate Image - Appear
             */
            /*gsap.fromTo(currentSlideActiveImage,
                {
                    y : 10,
                    opacity : 0
                },
                {
                    y : 0,
                    opacity : 1,
                    duration : 1,
                    ease: "power2.Out"
                },

            );*/

            /**
             * Animate Arrow - Appear
             */
            gsap.fromTo(sliderLink,
                {
                    y : 20,
                    opacity : 0
                },
                {
                    y : 0,
                    opacity : 1,
                    duration : .5,
                    ease: "easeInOut"
                },

            );

        };

        const handleBeforeChangeSlide = (currentSlide: number) => {

            let currentSlideActiveTitle = document.querySelector("[data-index='"+currentSlide+"'] .slide-title .title");
            let currentSlideActiveDate = document.querySelector("[data-index='"+currentSlide+"'] .slide-title .date");
            //let currentSlideActiveImage = document.querySelector("[data-index='"+currentSlide+"'] .slide-title .slide-image");
            let currentNavSteps = document.querySelector("[data-slide='"+currentSlide+"'].navigation-item");
            let sliderLink = document.querySelector(".slider-container .slide-link");

            currentNavSteps.classList.remove('active');
            /**
             * Animate Title - Disappear
             */
            gsap.to(currentSlideActiveTitle,
                {
                    y : -50,
                    opacity : 0,
                    duration : 0.75,
                    ease: "power2.in"
                },

            );

            /**
             * Animate Date - Disappear
             */
            gsap.to(currentSlideActiveDate,
                {
                    x : -15,
                    opacity : 0,
                    duration : 0.75,
                    ease: "power2.Out"
                },

            );

            /**
             * Animate Image - Disappear
             */
           /* gsap.to(currentSlideActiveImage,
                {
                    y : -15,
                    opacity : 0,
                    duration : 0.75,
                    ease: "power2.in"
                },

            );*/

            /**
             * Animate Arrow - Appear
             */
            gsap.fromTo(sliderLink,
                {
                    y : 0,
                    opacity : 1
                },
                {
                    y : -20,
                    opacity : 0,
                    duration : 0.50,
                    ease: "easeInOut"
                },

            );

            /*let els = document.getElementsByClassName('navigation-item active');
            if(els){
                while (els[0]) {
                    els[0].classList.remove('active')
                }
            }
            console.log(document.querySelector("[data-slide='"+(currentSlide+1)+"'].navigation-item "))

            document.querySelector("[data-slide='"+(currentSlide+1)+"'].navigation-item ").classList.toggle('active');
*/        };

        const handleClick = (e,index) => {
            let els = document.getElementsByClassName('navigation-item active');
            if(els){
                while (els[0]) {
                    els[0].classList.remove('active')
                }
            }
            e.target.classList.toggle('active');
            this.sliderRef.current.slickGoTo(index);
        }

        return (

            <Col>
                <Row className="section-carousel">

                    <Col  className="slider-container">

                        <div className="slider-steps text-center">
                            <div className="current-step">
                                <ul className="step-indicator">
                                    {propertiesDatasSlider.map((property,index) => (
                                        <li key={index + 1}>{index + 1}</li>
                                    ))}
                                </ul>
                            </div>
                            /&nbsp;
                            <span className="total-steps">{totalSlide}</span>
                        </div>

                        <div className="slider-subtitle text-center">{this.props.t('app.property_text')}</div>

{/*
                        <div className="slide-image">
                            <img src={imageSectionProprieteSlider} aria-hidden="true" alt=""/>
                        </div>
*/}

                        <Slider ref={this.sliderRef} {...settings}>

                            {propertiesDatasSlider.map((property,index) => (

                                <div className="slide-item" key={index}>
                                    <div className="slide-title text-center">
                                        <p className="title">{property.name}</p>
                                        <p className="date text-end">{this.props.t('app.since_text')} {property.date}</p>
                                    </div>
                                </div>
                            ))}

                        </Slider>

                        <div className="slider-navigation text-center">
                            <Row className="justify-content-center">
                                {propertiesDatasSlider.map((property, index) => (
                                    <button type="button" key={index} data-slide={index} className={`col-12 col-lg-auto navigation-item ${index === 0 ? 'active' : ''}`} onClick={(e) => handleClick(e,index)}><span>{property.name}</span></button>
                                ))}
                            </Row>
                        </div>

                        <a href={link} rel="noreferrer" target="_blank" className="slide-link text-center">{this.props.t('properties.sliderCta')}<span className="estate-name visually-hidden">{linkEstateName}</span></a>
                    </Col>
                </Row>
            </Col>

        )
    }

}

export default  withTranslation()(SliderLvmh);