import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {Trans, useTranslation} from 'react-i18next';

const Mentions = () => {

    const { t } = useTranslation(["mention"]);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.remove('page-homepage');
        document.body.classList.add('page-content');

    },[]);

    return (

        <>
            <Helmet>
                <title>{t('seo.name')} | {t('seo.title_mentions')}</title>
                <meta name="description" content={t('seo.description_mentions')}/>
            </Helmet>

            <main role="main" className="mentions">
                <Container fluid className="main-content">
                    <Row className="section">
                        <Col>
                            <Container >

                                <Row>
                                    <Col>
                                        <Row className="section-title justify-content-center">
                                            <Col xs="auto">
                                                <div className="text-title text-center">
                                                    <h1>{t('page.title')}</h1>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className="section-title-text small-marge">
                                    <Col xs="12" className="text-container">
                                        <div className="text-container-line">
                                            <h2 className="text-container-title h2 with-separator">
                                                <span>{t('page.section_1')}</span>
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">&nbsp;</Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_1_text"> </Trans>
                                                <Trans t={t} i18nKey="section_1_publishing"> </Trans>
                                                <Trans t={t} i18nKey="section_1_host"> </Trans>

                                                <Trans t={t} i18nKey="section_1_host_data">
                                                    <ul className="mention-list">
                                                        <li> Adresse : [XXX]</li>
                                                        <li>Tél. : +33 (0)1 xx xx xx xx</li>
                                                        <li> Fax : +33 (01) xx xx xx xx</li>
                                                        <li>RCS [ville] : xxx xxx xxx</li>
                                                    </ul>
                                                </Trans>
                                                <Trans t={t} i18nKey="section_1_agency"></Trans>
                                                <Trans t={t} i18nKey="section_1_agency_data">
                                                    <ul className="mention-list">
                                                        <li>Design </li>
                                                        <li>Développement</li>
                                                    </ul>
                                                </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text small-marge">
                                    <Col xs="12" className="text-container">
                                        <div className="text-container-line">
                                            <h2 className="text-container-title h2 with-separator">
                                                <span>{t('page.section_2')}</span>
                                            </h2>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">I.</span>{t('section_2_1_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_2_1_text" ></Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">II.</span>{t('section_2_2_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_2_2_text" ></Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">III.</span>{t('section_2_3_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_2_3_text" ></Trans>

                                                <b><Trans t={t} i18nKey="section_2_3_credit" ></Trans></b>
                                                <Trans t={t} i18nKey="section_2_3_credit_text" ></Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">IV.</span>{t('section_4_1_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_4_1_text" ></Trans>
                                                <Trans t={t} i18nKey="section_4_1_list" >
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                                <Trans t={t} i18nKey="section_4_2_text" > </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">V.</span>{t('section_5_1_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_5_1_text" ></Trans>
                                                <Trans t={t} i18nKey="section_5_1_list" >
                                                    <ul className="mention-list">
                                                        <li></li>
                                                        <li></li>
                                                    </ul>
                                                </Trans>
                                                <Trans t={t} i18nKey="section_5_2_text" > </Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">VI.</span>{t('section_6_1_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_6_1_text" ></Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">VII.</span>{t('section_7_1_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_7_1_text" ></Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">VIII.</span>{t('section_8_1_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_8_1_text" ></Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">IX.</span>{t('section_9_1_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_9_1_text" ></Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="section-title-text left-title">
                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <h3 className="text-container-title h3">
                                                <span className="text-container-title-number">X.</span>{t('section_10_1_title')}
                                            </h3>
                                        </div>
                                    </Col>

                                    <Col xs="12" lg="6" className="text-container">
                                        <div className="text-container-line">
                                            <div className="text-container-content">
                                                <Trans t={t} i18nKey="section_10_1_text" ></Trans>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>

            </main>

        </>
    );
}

export default Mentions;